<style lang="scss" scoped>
$test-font-size: 18px !default;
.home-statistics {
  .card {
    cursor: pointer;
    padding: 0 53px 0 47px;
    height: 72px;
    line-height: 72px;
    font-size: 24px;
    color: white;

    .num {
      float: right;
      font-size: 32px;
    }
  }

  .el-col:last-child .card {
    border-radius: 0 6px 6px 0;
  }

  .el-col:first-child .card {
    border-radius: 6px 0 0 6px;
  }
}

.ve-pie-card {
  padding: 32px;
  background-color: white;
  border-radius: 6px;

  > .header {
    height: 24px;
    color: #5E5E5E;
    font-size: 24px;
    line-height: 1em;
  }
}

.organization-statistics {
  padding: 27px 16px 23px 16px;
  color: #5E5E5E;
  font-size: $test-font-size;
  line-height: 1em;
}

.call-quality {
  padding: 0 37px 27px 50px;
  background-color: white;
  border-radius: 6px;

  & > .header {
    padding: 32px 0 27px 0;
    height: 24px;
    color: #5E5E5E;
    font-size: 24px;
    line-height: 1em;
  }

  & > .main {
    padding-left: 30px;
  }

  .call-quality-item {
    display: flex;
    width: 100%;
    margin: 20px 0;
    line-height: 20px;

    & > .header {
      width: 115px;
      font-size: 20px;
      color: #5E5E5E;

      & > .icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 21px;
        border-radius: 50%;
      }
    }

    & > .main {
      flex: auto;
      margin: 4px 0;
    }

    & > .footer {
      width: 44px;
      font-size: 20px;
      color: #5E5E5E;
      text-align: right;
    }
  }
}
</style>
<template>
  <div>
    <el-row class="home-statistics">
      <el-col v-for="item in topCardData" :key="item.title" :span="8">
        <div v-if="item.disable !== true"
            :style="`background-color: ${item.bgColor}`"
            class="card"
            @click="$router.push(item.path)"
        >
          <span>{{ item.title }}</span>
          <span class="num">{{ item.value }}</span>
        </div>
      </el-col>
    </el-row>
    <div class="organization-statistics">{{ $t('home.deviceStatistics') }}</div>
    <el-row :gutter="30">
      <el-col :span="8">
        <div class="ve-pie-card">
          <div class="header">{{ $t('device.deviceStatus') }}</div>
          <echart-pie
              v-model="deviceData"
              icon="-device-status"
              @echartEvents="deviceEchartEvents"
          ></echart-pie>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="ve-pie-card">
          <div class="header">{{ $t('home.sipAccountStatus') }}</div>
          <echart-pie
              v-model="accountData"
              icon="-account-status"
              @echartEvents="accountEchartEvents"
          ></echart-pie>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="ve-pie-card">
          <div class="header">{{ $t('home.modelStatistics') }}</div>
          <echart-pie
              v-model="modelData"
              :modelColor="modelColor"
              :showValue="showModelValue"
              icon="-model-statistics"
              @echartEvents="modelEchartEvents"
          ></echart-pie>
        </div>
      </el-col>
    </el-row>
<!--    <div class="call-quality">-->
<!--      <div class="header">-->
<!--        {{ $t('callStatistics.callQuality') }}-->
<!--        <el-popover placement="right-start" trigger="hover" width="500">-->
<!--          <div v-html="$t('callStatistics.callQualityTip')"></div>-->
<!--          <i slot="reference" class="el-icon-warning-outline"></i>-->
<!--        </el-popover>-->
<!--      </div>-->
<!--      <div class="main">-->
<!--        <div v-for="item in callQualityData" :key="item.title" class="call-quality-item">-->
<!--          <div class="header">-->
<!--            <span :style="{ backgroundColor: item.color }" class="icon"></span>{{ item.title }}-->
<!--          </div>-->
<!--          <div class="main">-->
<!--            <el-progress-->
<!--                :color="item.color"-->
<!--                :percentage="item.percentage"-->
<!--                :show-text="false"-->
<!--                :stroke-width="12"-->
<!--            ></el-progress>-->
<!--          </div>-->
<!--          <div class="footer">{{ item.value }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {homeTotal} from '@/api/home'
import EchartPie from './echart-pie'
import {
  colorPrimary10,
  colorPrimary11,
  colorPrimary12,
  colorPrimary13,
  colorPrimary14,
  colorPrimary2,
  colorPrimary3,
  colorPrimary4,
  colorPrimary6,
  colorPrimary7,
  colorPrimary8,
  colorPrimary9,
} from '@/style/variables.scss'

export default {
  name: 'statistics',
  components: {
    EchartPie,
  },
  data() {
    return {
      topCardData: [
        {
          title: this.$t('device.name'),
          value: 0,
          path: 'device',
          bgColor: colorPrimary10,
        },
        {
          title: this.$t('account'),
          value: 0,
          path: 'sipAccount',
          bgColor: colorPrimary3,
        },
        {
          title: this.$t('site.site'),
          value: 0,
          path: 'ssite',
          bgColor: colorPrimary4,
        },
        {
          title: this.$t('call'),
          value: 0,
          path: 'callStatistics',
          bgColor: colorPrimary8,
          disable: true
        },
      ],
      accountData: [],
      deviceData: [],
      modelData: [],
      modelColor: [],
      showModelValue: true,
      callQualityData: [
        {
          title: this.$t('dist.callQuality.good'),
          value: 0,
          percentage: 0,
          color: '#B7F4EC',
          icon: 'account-status',
        },
        {
          title: this.$t('dist.callQuality.fair'),
          value: 0,
          percentage: 0,
          color: '#AFC5FD',
          icon: 'device-status',
        },
        {
          title: this.$t('dist.callQuality.poor'),
          value: 0,
          percentage: 0,
          color: '#FED691',
          icon: 'model-statistics'
        },
      ],
    }
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
    ...mapState('memory', ['language']),
    ...mapGetters('dist', ['accountStatus', 'deviceStatus']),
  },
  watch: {
    sidebarSite() {
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      homeTotal({
        site: this.sidebarSite,
      })
          .then(({data}) => {
            const rows = data.result.rows[0]
            const  statusColor = {
              M3: colorPrimary7,
              M5: colorPrimary3,
              M7: colorPrimary4,
              M8: colorPrimary11,
              H3P: colorPrimary10,
              H3G: colorPrimary9,
              H3W: colorPrimary12,
              H6: colorPrimary2,
              H6W: colorPrimary13,
              H2: colorPrimary8,
              H2P: colorPrimary14,
            }
            // 统计
            this.topCardData[0].value = rows.devices
            this.topCardData[1].value = rows.accounts
            this.topCardData[2].value = rows.sites
            this.topCardData[3].value = rows.calls
            // 用户状态统计
            this.accountData = [
              {name: this.accountStatus[1], value: rows.account.normal, statusIdx: 1},
              {name: this.accountStatus[2], value: rows.account.abnormal, statusIdx: 2},
              {name: this.accountStatus[0], value: rows.account.unassigned, statusIdx: 0},
            ]

            if (Object.entries(rows.modelStatus).length) {
              const modelData = []
              this.modelColor = []
              Object.entries(rows.modelStatus).forEach(e => {
                this.modelColor.push(statusColor[e[0]])
                modelData.push({
                  name: e[0] == 'unKown' ? this.$t('home.unKown') : e[0],
                  value: e[1]
                })
              })
              this.modelData = modelData
            } else {
              this.showModelValue = false
              this.modelColor.push('#CCC')
              this.modelData =
                  [{
                    name: 'No Data',
                    value: 1
                  }]
            }
            // 设备状态统计
            this.deviceData = [
              {name: this.deviceStatus[1], value: rows.device.online, statusIdx: 1},
              {name: this.deviceStatus[0], value: rows.device.offline, statusIdx: 0},
              {name: this.deviceStatus[2], value: rows.device.abnormal, statusIdx: 2},
            ]
            // 设备状态
            this.callQualityData[0].value = rows.callQuality.good
            this.callQualityData[1].value = rows.callQuality.fair
            this.callQualityData[2].value = rows.callQuality.poor
            this.callQualityData[0].percentage = (rows.callQuality.good / rows.callQuality.all) * 100
            this.callQualityData[1].percentage = (rows.callQuality.fair / rows.callQuality.all) * 100
            this.callQualityData[2].percentage = (rows.callQuality.poor / rows.callQuality.all) * 100
          })
          .catch(() => {})
    },
    accountEchartEvents(item) {
      this.$router.push({path: '/sipAccount', query: {status: this.accountData[item.dataIndex].statusIdx}})
    },
    deviceEchartEvents(item) {
      this.$router.push({path: '/device', query: {deviceStatus: this.deviceData[item.dataIndex].statusIdx}})
    },
    modelEchartEvents(item) {
      if (this.showModelValue)
        this.$router.push({path: '/device', query: {model: item.data.name}})
    },
  },
}
</script>
