<template>
  <div class="page no-header">
    <div class="page-main bg-transparent">
      <el-tabs class="home-tab" v-model="activeName">
        <el-tab-pane :label="$t('home.deviceDtatistics')" name="statistics" lazy>
          <Statistics></Statistics>
        </el-tab-pane>
        <!-- EDM 第一阶段需隐藏模块 <el-tab-pane :label="$t('home.deviceDistribution')" name="distribution" lazy>
          <Distribution></Distribution>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Statistics from './statistics'
// import Distribution from './distribution'

export default {
  name: 'Home',
  components: {
    Statistics,
    // Distribution,
  },
  data() {
    return {
      activeName: 'statistics',
    }
  },
}
</script>
